
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.account-additional-info {
  padding: 38px 32px 36px;
  display: flex;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }

  @include for-size(phone-portrait-down) {
    width: 100vw;
    margin-left: -20px;
    padding: 20px;
  }

  &__block {
    flex-grow: 1;
    padding-right: 32px;

    &:not(:last-child) {
      @include for-size(tablet-landscape-down) {
        margin-bottom: 50px;
      }
      @include for-size(phone-portrait-down) {
        margin-bottom: 32px;
      }
    }

    @include for-size(tablet-landscape-down) {
      padding-right: 0;
    }

    h2 {
      margin-bottom: 26px;
    }

    table {
      tr {
        &:not(:last-child) {
          td {
            padding-bottom: 24px;
            @include for-size(phone-portrait-down) {
              padding-bottom: 20px;
            }
          }
        }

        td {
          &:first-child {
            color: $color-grayscale-60;
            padding-right: 40px;
            @include for-size(phone-portrait-down) {
              padding-right: 20px;
            }
          }
          @include for-size(phone-portrait-down) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
